<template>
  <div v-show="checkEvent" id="dashboard-content">
    <section v-if="hideForm">
      <div class="alert alert-warning">{{ messageHideForm }}</div>
    </section>
    <section v-else id="profile-assesment">
      <div v-if="checkStatusProfile" class="alert alert-warning">
        <span>Silakan isi data profile anda terlebih dahulu. </span>
        <router-link to="/user/profil/publisher" class="text-primary">
          <a>Lengkapi profil</a>
        </router-link>
      </div>
      <form v-else @submit.prevent="createAssesment()">
        <h4 class="mb-4">Penilaian Buku SMK Tahun 2023</h4>
        <div
          v-if="$store.state.messageStatusErrorAssesment"
          class="alert alert-danger"
        >
          <span v-html="$store.state.messageErrorAssesment"></span>
          <button
            @click="$store.state.messageStatusErrorAssesment = false"
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <p class="mb-4">Selamat datang Bapak/Ibu. 
        Selamat, Buku ini sudah berhasil masuk tahap Pra-registrasi.</p>

        <p class="mb-4">Selanjutnya di tahap Registrasi, diwajibkan untuk mengunggah file buku yang akan dinilai serta data lain di formulir registrasi. </p>

        <p class="mb-4">Silakan mengisi formulir di bawah ini. Mohon mencantumkan data sebenar-benarnya untuk memudahkan proses penilaian. 
        Tanda bintang (*) wajib diisi.</p>

        <p class="mb-4">Terima kasih atas partisipasi Bapak/Ibu dalam mengikuti penilaian buku teks pendamping SMK/MAK Tahun 2023.</p>
        
        <h5 class="font-weight-bold mb-2"><u>Registrasi</u></h5>
        
        <p class="mb-4">Wajib mengisi data-data di bawah ini.</p>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="assesmentEventId" class="form-label">
                Kegiatan Penilaian *
              </label>
              <input
                readonly
                v-model="assesmentEvent"
                id="assesmentEventId"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="assesmentEventId" class="form-label">Tipe Buku *</label>
              <input
                readonly
                id="assesmentEventId"
                value="Buku Teks Pendamping"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="judulBuku" class="form-label">Judul Buku *</label>
              <input
                required
                id="judulBuku"
                type="text"
                class="form-control"
                placeholder="Masukkan judul buku"
                v-model="assesment.title"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="level" class="form-label">Jenjang *</label>
              <input
                readonly
                id="level"
                class="form-control"
                v-model="assesment.level"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="class" class="form-label">Kelas *</label>
              <select v-model="assesment.class" @change="selectClass($event.target.value)" required class="form-control" id="class">
                <option value="" disabled selected>Pilih Kelas</option>
                <option value="10">X</option>
                <option value="11">XI</option>
                <option value="12">XII</option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="subject" class="form-label">Mata Pelajaran *</label>
              <select
                required
                v-model="assesment.subject"
                id="subject"
                class="form-control"
              >
                <option :value="assesment.subject">{{ assesment.subject }}</option>
                <option v-for="(subject, index) in subjects" :key="index" :value="subject">{{subject}}</option>
              </select>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="penulis" class="form-label">Nama Penulis *</label>
              <input
                required
                id="penulis"
                type="text"
                class="form-control"
                placeholder="Masukkan nama penulis"
                v-model="assesment.author"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="editor" class="form-label">Nama Editor *</label>
              <input
                required
                id="editor"
                type="text"
                class="form-control"
                placeholder="Masukkan nama editor"
                v-model="assesment.editor"
              />
            </div>
          </div>
        </div>  

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="isbn" class="form-label">ISBN (Jika ada)</label>
              <input
                id="isbn"
                type="text"
                class="form-control"
                placeholder="Masukkan ISBN"
                v-model="assesment.isbn"
              />
              <small
                >ISBN Wajib dikirimkan setelah buku dinyatakan lolos
                penilaian.</small
              >
            </div>
          </div>
          
          <div class="col-12">
            <div class="form-group">
              <label for="tahunTerbit" class="form-label">Tahun Terbit *</label>
              <select 
                v-model="assesment.publication_year"
                id="tahunTerbit"
                class="form-control"
                required
              >
                <option value="2019" selected>2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>
         
          <div class="col-12">
            <div class="form-group">
              <label for="imprint" class="form-label">Imprint *</label>
              <input
                id="imprint"
                type="text"
                class="form-control"
                placeholder="Masukkan nama dagang"
                v-model="assesment.imprint"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="edition" class="form-label">Edisi Ke- *</label>
              <input
                id="edition"
                type="number"
                class="form-control"
                placeholder="Masukkan edisi"
                v-model="assesment.edition"
                required
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="jumlahHalPrelim" class="form-label">
                Jumlah Halaman Prelims *
              </label>
              <input
                type="number"
                id="jumlahHalPrelim"
                class="form-control"
                v-model="assesment.total_prelim_page"
                placeholder="Masukkan jumlah halaman prelims"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="jumlahHalIsi" class="form-label">
                Jumlah Halaman Isi *
              </label>
              <input
                type="number"
                id="jumlahHalIsi"
                class="form-control"
                v-model="assesment.total_content_page"
                placeholder="Masukkan jumlah halaman isi"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="jumlahHalPostlim" class="form-label">
                Jumlah Halaman Postlims *
              </label>
              <input
                type="number"
                id="jumlahHalPostlim"
                class="form-control"
                v-model="assesment.total_ending_page"
                placeholder="Masukkan jumlah halaman postlims"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="sinopsis" class="form-label">
                Ringkasan isi buku *
              </label>
              <textarea
                id="sinopsis"
                rows="5"
                class="form-control"
                placeholder="Masukkan ringkasan"
                v-model="assesment.synopsis"
                required
              ></textarea>
            </div>
          </div>
       
          <div class="col-12">
            <div class="form-group">
              <label for="designer" class="form-label">Nama Desainer *</label>
              <input
                id="designer"
                type="text"
                class="form-control"
                placeholder="Masukkan nama desainer"
                v-model="assesment.designer"
                required
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="ilustrator" class="form-label">Nama Ilustrator *</label>
              <input
                id="ilustrator"
                type="text"
                class="form-control"
                placeholder="Masukkan nama ilustrator"
                v-model="assesment.illustrator"
                required
              />
            </div>
          </div>
        </div>
        <hr />
        <h6 class="font-weight-bold mb-2" id="dataBerkasBuku">
          <u>Data Berkas Buku</u>
        </h6>
        <p class="mb-4">
          Silakan mengisi data-data terkait berkas buku. Mohon untuk
          <b>mengompres</b> terlebih dahulu berkas sampai batas yang sudah
          ditentukan di formulir.
        </p>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="fileOriginal" class="form-label">
                Berkas PDF Orisinal dan Lengkap
                <span class="text-muted">(PDF, ukuran maks 50mb)</span>
              </label>
              <small
                v-if="validation.file_original"
                class="text-danger d-block"
              >
                {{ validation.file_original }}
              </small>
              <input
                type="file"
                id="fileOriginal"
                class="form-control"
                @change="selectFile($event, 'file_original')"
                @click="loading.file_original = false"
              />
              <input type="hidden" v-model="assesment.file_original" />
              <div class="my-1">
                <button
                  v-if="!loading.file_original && !link.file_original"
                  @click="uploadFile('file_original')"
                  type="button"
                  class="btn btn-info btn-sm"
                  id="uploadFileOriginal"
                >
                  Unggah
                </button>
                <button
                  v-if="loading.file_original"
                  type="button"
                  class="btn btn-info btn-sm"
                >
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                  Sedang Diproses
                </button>
                <button
                  v-else-if="!loading.file_original && link.file_original"
                  type="button"
                  class="btn btn-secondary btn-sm"
                  disabled
                >
                  Berhasil Diunggah <i class="fas fa-check"></i>
                </button>
                &nbsp;&nbsp;<small>{{ link.file_original }}</small>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="fileNoIdentity" class="form-label">
                Berkas PDF Tanpa Identitas
                <span class="text-muted">(PDF, ukuran maks 50mb)</span>
              </label>
              <small
                v-if="validation.file_no_identity"
                class="text-danger d-block"
              >
                {{ validation.file_no_identity }}
              </small>
              <input
                type="file"
                id="fileNoIdentity"
                class="form-control"
                @change="selectFile($event, 'file_no_identity')"
                @click="loading.file_no_identity = false"
              />
              <input type="hidden" v-model="assesment.file_no_identity" />
              <div class="my-1">
                <button
                  v-if="!loading.file_no_identity && !link.file_no_identity"
                  @click="uploadFile('file_no_identity')"
                  type="button"
                  class="btn btn-info btn-sm"
                  id="uploadFileNoIdentity"
                >
                  Unggah
                </button>
                <button
                  v-if="loading.file_no_identity"
                  type="button"
                  class="btn btn-info btn-sm"
                >
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                  Sedang Diproses
                </button>
                <button
                  v-else-if="!loading.file_no_identity && link.file_no_identity"
                  type="button"
                  class="btn btn-secondary btn-sm"
                  disabled
                >
                  Berhasil Diunggah <i class="fas fa-check"></i>
                </button>
                &nbsp;&nbsp;<small>{{ link.file_no_identity }}</small>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="fileCapaianPembelajaran" class="form-label">
                Berkas PDF Capaian Pembelajaran
                <span class="text-muted">(PDF, ukuran maks 5mb)</span>
              </label>
              <small
                v-if="validation.file_capaian_pembelajaran"
                class="text-danger d-block"
              >
                {{ validation.file_capaian_pembelajaran }}
              </small>
              <input
                type="file"
                id="fileCapaianPembelajaran"
                class="form-control"
                @change="selectFile($event, 'file_capaian_pembelajaran')"
                @click="loading.file_capaian_pembelajaran = false"
              />
              <input
                type="hidden"
                v-model="assesment.file_capaian_pembelajaran"
              />
              <div class="my-1">
                <button
                  v-if="
                    !loading.file_capaian_pembelajaran &&
                      !link.file_capaian_pembelajaran
                  "
                  @click="uploadFile('file_capaian_pembelajaran')"
                  type="button"
                  class="btn btn-info btn-sm"
                  id="uploadFileCapaianPembelajaran"
                >
                  Unggah
                </button>
                <button
                  v-if="loading.file_capaian_pembelajaran"
                  type="button"
                  class="btn btn-info btn-sm"
                >
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                  Sedang Diproses
                </button>
                <button
                  v-else-if="
                    !loading.file_capaian_pembelajaran &&
                      link.file_capaian_pembelajaran
                  "
                  type="button"
                  class="btn btn-secondary btn-sm"
                  disabled
                >
                  Berhasil Diunggah <i class="fas fa-check"></i>
                </button>
                &nbsp;&nbsp;<small>{{ link.file_capaian_pembelajaran }}</small>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="font-weight-bold mb-2" id="dataBerkasLegalitas">
          <u>Data Berkas Legalitas</u>
        </h6>
        <p class="mb-4">Silakan mengisi data-data terkait berkas legalitas</p>
        <div
          v-if="$store.state.messageStatusErrorassesment"
          class="alert alert-danger"
        >
          {{ $store.state.messageErrorassesment }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="suratPernyataanPenulis" class="form-label">
                Surat pernyataan penerbit tentang hak terbit dan keaslian naskah
                <span class="text-muted">(PDF, ukuran maks 2mb)</span></label
              >
              <small
                v-if="validation.surat_pernyataan_penulis"
                class="text-danger d-block"
              >
                {{ validation.surat_pernyataan_penulis }}
              </small>
              <input
                type="file"
                id="suratPernyataanPenulis"
                class="form-control"
                @change="selectFile($event, 'surat_pernyataan_penulis')"
                @click="loading.surat_pernyataan_penulis = false"
              />
              <input
                type="hidden"
                v-model="assesment.surat_pernyataan_penulis"
              />
              <small>
                Silakan mengunduh contoh berkas pengajuan pada link berikut ini
                <a href="https://bit.ly/3U8bW33" target="_blank">
                  https://bit.ly/3U8bW33
                </a>
              </small>
              <div class="my-1">
                <button
                  v-if="!loading.surat_pernyataan_penulis && !link.surat_pernyataan_penulis"
                  @click="uploadFile('surat_pernyataan_penulis')"
                  type="button"
                  class="btn btn-info btn-sm"
                  id="uploadSuratPernyataanPenulis"
                >
                  Unggah
                </button>
                <button
                  v-if="loading.surat_pernyataan_penulis"
                  type="button"
                  class="btn btn-info btn-sm"
                >
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                  Sedang Diproses
                </button>
                <button
                  v-else-if="!loading.surat_pernyataan_penulis && link.surat_pernyataan_penulis"
                  type="button"
                  class="btn btn-secondary btn-sm"
                  disabled
                >
                  Berhasil Diunggah <i class="fas fa-check"></i>
                </button>
                &nbsp;&nbsp;<small>{{ link.surat_pernyataan_penulis }}</small>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="mb-4 mt-2">
              <b>Catatan</b> : Kegiatan pengiriman buku fisik akan dilaksanakan
              setelah penilaian tahap 1 selesai.
            </div>
            <small
              v-if="validation.accept_tnc && !assesment.accept_tnc"
              class="text-danger"
            >
              Mohon ceklis untuk menyutujui persyaratan di bawah ini
            </small>
            <div class="form-check">
              <input
                class="form-check-input"
                @change="!assesment.accept_tnc"
                v-model="assesment.accept_tnc"
                type="checkbox"
                id="accept"
              />
              <label class="form-check-label" for="accept">
                Saya menyatakan bahwa data di atas adalah benar. *
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="$store.state.messageStatusAssesment"
          class="alert alert-success"
        >
          <button
            @click="$store.state.messageStatusAssesment = false"
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <span>
            Buku berhasil dikirim. Selanjutnya silakan tunggu proses
            pemeriksaan dan penilaian buku
          </span>
        </div>
        <div v-if="assesment.status == 'draft'" class="text-right">
          <button
            type="submit"
            @click="assesment.status = 'draft'"
            class="btn btn-secondary px-3"
          >
            {{
              $store.state.loadSubmitAssesment
                ? "Sedang diproses.."
                : "Simpan sebagai draf"
            }}
          </button>
          <button
            type="submit"
            @click="assesment.status = 'publish'"
            class="btn btn-primary mx-3"
          >
            {{
              $store.state.loadSubmitAssesment
                ? "Sedang diproses.."
                : "Simpan permanen"
            }}
          </button>
        </div>
        <div v-else class="text-right">
          <button
            type="submit"
            @click="assesment.status = 'draft'"
            class="btn btn-secondary px-3"
          >
            {{
              $store.state.loadSubmitAssesment
                ? "Sedang diproses.."
                : "Simpan sebagai draf"
            }}
          </button>
          <button
            type="submit"
            @click="assesment.status = 'publish'"
            class="btn btn-primary mx-3"
          >
            {{
              $store.state.loadSubmitAssesment
                ? "Sedang diproses.."
                : "Simpan permanen"
            }}
          </button>
        </div>
        <div v-if="assesment.status == 'draft'" class="text-right mx-3 mt-2">
          <small>* Simpan permanen bila data buku siap untuk dinilai</small><br/>
          <small>* Simpan draf bila data belum siap, jangan lupa mengubah draf menjadi permanen.</small>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2"
import axios from "axios";

export default {
  data() {
    return {
      hideForm: false,
      messageHideForm: "",
      id: this.$route.query.id ?? null,
      checkStatusProfile: false,
      checkEvent: false,
      subjects: [],
      assesment: {
        status: "draft",
        assessment_event_id: this.$route.query.event_id ?? "",
        title: "",
        book_type: "btp",
        subject: "",
        isbn: "",
        publication_year: "",
        level: "SMK",
        class: "",
        imprint: "",
        edition: "",
        total_prelim_page: "",
        total_content_page: "",
        total_ending_page: "",
        synopsis: "",
        author: "",
        editor: "",
        designer: "",
        illustrator: "",
        file_original: "",
        file_no_identity: "",
        file_capaian_pembelajaran: "",
        surat_pernyataan_penulis: "",
        accept_tnc: false,
      },
      file: {
        file_original: null,
        file_no_identity: null,
        file_capaian_pembelajaran: null,
        surat_pernyataan_penulis: null,
      },
      loading: {
        file_original: false,
        file_no_identity: false,
        file_capaian_pembelajaran: false,
        surat_pernyataan_penulis: false,
      },
      validation: {
        file_original: "",
        file_no_identity: "",
        file_capaian_pembelajaran: "",
        surat_pernyataan_penulis: "",
        accept_tnc: false,
      },
      link: {
        file_original: "",
        file_no_identity: "",
        file_capaian_pembelajaran: "",
        surat_pernyataan_penulis: "",
      },
    };
  },
  computed: {
    ...mapState(["eventPenilaian", "subjects"]),
    assesmentEvent() {
      // Check if there is no data from fetch book detail publisher
      let eventId = this.id ? this.assesment.assessment_event_id : this.$route.query.event_id;
      let result = this.eventPenilaian?.find((event) => event.id == eventId)?.title;
      return result;
    },
  },
  methods: {
    ...mapActions([
      "submitAssesment",
      "uploadFileAssesment",
      "fetchBookByPublisher",
      "fetchAllEventPenilaian",
      "fetchPublisherProfile",
    ]),
    selectClass(level) {
      axios.get(`https://penilaian-buku.cloudapp.web.id/api/penilaian/book/subjectSMK?level=${level}`)
      .then(response => {
        this.subjects = response.data;
        this.assesment.subject = response.data[0]
      })
    },
    selectFile(event, state) {
      let file = event.target.files[0];
      this.file[state] = file;
      this.link[state] = "";
    },
    uploadFile(state) {
      let file = null;

      // Validation check if file null
      if (this.file[state] !== null) {
        this.loading[state] = true;
        file = this.file[state];
      } else {
        this.validation[state] = "Silakan pilih berkas terlebih dahulu";
      }

      // Upload file to server
      if (file !== null) {
        this.uploadFileAssesment({
          file,
          eventId: parseInt(this.assesment.assessment_event_id),
          field: event.target.id,
        })
          .then((res) => {
            if (res.status == "success") {
              this.assesment[state] = res.file;
              this.link[state] = res.file;
              this.loading[state] = true;
              this.validation[state] = "";
            } else {
              this.validation[state] = res.message;
            }
          })
          .catch((err) => {
            if (err == 403) {
              this.validation[state] = "Ukuran file terlalu besar";
            }
          })
          .finally(() => {
            this.loading[state] = false;
          });
      }
    },
    createAssesment() {
      if (this.assesment.file_original == "") {
        this.validation.file_original = "Silahkan unggah berkas terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.file_no_identity == "") {
        this.validation.file_no_identity = "Silahkan unggah berkas terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.file_capaian_pembelajaran == "" || this.assesment.file_capaian_pembelajaran == null) {
        this.validation.file_capaian_pembelajaran = "Silahkan unggah berkas terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.surat_pernyataan_penulis == "") {
        this.validation.surat_pernyataan_penulis = "Silahkan unggah berkas terlebih dahulu";
        document.getElementById("dataBerkasLegalitas").scrollIntoView();
      } else if (!this.assesment.accept_tnc) {
        this.validation.accept_tnc = true;
        document.getElementById("dataBerkasLegalitas").scrollIntoView();
      } else if (this.assesment.file_original != this.link.file_original) {
        this.validation.file_original = "Silahkan klik tombol unggah terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.file_no_identity != this.link.file_no_identity) {
        this.validation.file_no_identity = "Silahkan klik tombol unggah terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.file_capaian_pembelajaran != this.link.file_capaian_pembelajaran) {
        this.validation.file_capaian_pembelajaran = "Silahkan klik tombol unggah terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else if (this.assesment.surat_pernyataan_penulis != this.link.surat_pernyataan_penulis) {
        this.validation.surat_pernyataan_penulis = "Silahkan klik tombol unggah terlebih dahulu";
        document.getElementById("dataBerkasBuku").scrollIntoView();
      } else {
        this.id != null && (this.assesment["id"] = this.id);
        this.assesment["type_request"] = this.id == null ? "post" : "put";
        Swal.fire({
          title: 'Perhatian',
          icon: 'warning',
          text: 'Pastikan Bapak/Ibu mengklik tombol unggah berkas terlebih dahulu untuk menyetorkan berkas buku, sebelum mengklik tombol simpan',
          showCancelButton: true,
          confirmButtonText: 'Sudah, lanjutkan kirim data saya',
          cancelButtonText: 'Ok, saya cek kembali',
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitAssesment(this.assesment).then((res) => {
              if (res.data.status == "success") {
                this.$store.state.messageStatusAssesment = false;
                this.$store.state.loadSubmitAssesment = false;
                this.$router.push({ path: "/user/penilaian" });
              } else {
                this.$store.state.messageStatusAssesment = false;
                this.$store.state.loadSubmitAssesment = false;
                scrollTo({ top: 0, behavior: "smooth" });
              }
            });
          }
        })
      }
    },
  },
  created() {
    this.assesment.book_type = "btp";

    const eventId = [14, 21];
    const checkEvent = eventId.find(id => this.$route.query.event_id == id);
    this.checkEvent = checkEvent ? true : false;

    if(checkEvent) {
      if (this.id) {
        this.fetchBookByPublisher(this.id)
          .then((res) => {
            if (res.data.length !== 0) {
              let data = res.data;
              this.assesment.title = data.title;
              this.assesment.assessment_event_id = data.assessment_event_id;
              this.assesment.book_type = data.book_type;
              this.assesment.class = data.class;
              this.assesment.subject = data.subject;
              this.assesment.isbn = data.isbn;
              // this.assesment.publication_year = data.publication_year;
              this.assesment.level = data.level;
              this.assesment.imprint = data.imprint;
              this.assesment.edition = data.edition;
              this.assesment.total_prelim_page = data.total_prelim_page;
              this.assesment.total_content_page = data.total_content_page;
              this.assesment.total_ending_page = data.total_ending_page;
              this.assesment.synopsis = data.synopsis;
              this.assesment.author = data.author;
              this.assesment.editor = data.editor;
              this.assesment.designer = data.designer;
              this.assesment.illustrator = data.illustrator;
              this.assesment.file_original = data.file_original;
              this.assesment.file_no_identity = data.file_no_identity;
              this.assesment.file_capaian_pembelajaran =
                data.file_capaian_pembelajaran;
              this.assesment.surat_pernyataan_penulis =
                data.surat_pernyataan_penulis;
              this.assesment.accept_tnc = data.accept_tnc == 1 ? true : false;
  
              // Show file if uploaded
              this.link.file_original = data.file_original;
              this.link.file_no_identity = data.file_no_identity;
              this.link.file_capaian_pembelajaran =
                data.file_capaian_pembelajaran;
              this.link.surat_pernyataan_penulis = data.surat_pernyataan_penulis;

              axios.get(`https://penilaian-buku.cloudapp.web.id/api/penilaian/book/subjectSMK?level=${data.class}`)
              .then(response => {
                this.subjects = response.data;
              })
  
              // Check event for hide form
              this.fetchAllEventPenilaian().then((res) => {
                let checkEvent = res.data.find(
                  (event) => event.id == this.assesment.assessment_event_id
                );
                if (!checkEvent && this.id === null) {
                  this.messageHideForm = "Event tidak tersedia";
                  this.hideForm = true;
                }
                
                // Logic hide form for revision
                let step = data.step;
                let status = data.status;
                let allowedRevision =
                  (step == "Submission" && status == "Menunggu") ||
                  (step == "Submission" && status == "Lengkap tapi Tidak Valid") ||
                  (step == "Submission" && status == "Perlu Revisi") ||
                  (step == "Prapenilaian" && status == "Layak dengan Perbaikan") ||
                  (step == "Pleno" && status == "Revisi Minor") ||
                  (step == "Pleno Revisi" && status == "Menunggu");
                  (step == "Pleno Revisi" && status == "Perlu Revisi");

                if (checkEvent?.submit_revision == "0") {
                  this.messageHideForm = "Data buku sudah tidak dapat diperbaharui";
                  this.hideForm = true;
                } else if (!allowedRevision && data.locked == "1") {
                  this.messageHideForm = "Buku sedang dalam proses penilaian dan sementara tidak dapat diperbaharui";
                  this.hideForm = true;
                }

                if (checkEvent.slug !== "btp") {
                  if (this.id) {
                    this.messageHideForm = "Buku tidak ditemukan";
                  } else {
                    this.messageHideForm = "Event tidak tersedia";
                  }
                  this.hideForm = true;
                }
              });
            } else {
              this.messageHideForm = "Buku tidak ditemukan";
              this.hideForm = true;
            }
          })
          .catch(() => {
            this.messageHideForm = "Buku tidak dapat diakses";
            this.hideForm = true;
          });
      } else {
        this.fetchAllEventPenilaian().then((res) => {
          let checkEvent = res.data.find(
            (event) => event.id == this.assesment.assessment_event_id
          );
          if (!checkEvent && this.id === null) {
            this.messageHideForm = "Event tidak tersedia";
            this.hideForm = true;
          }
          if (checkEvent?.submit_revision == "0") {
            this.messageHideForm =
              "Buku ada di dalam tahap penilaian. Untuk sementara tidak bisa diperbaharui.";
            this.hideForm = true;
          }
          if (checkEvent.slug !== "praregistrasi" && checkEvent.slug !== "btp") {
            if (this.id) {
              this.messageHideForm = "Buku tidak ditemukan";
            } else {
              this.messageHideForm = "Event tidak tersedia";
            }
            this.hideForm = true;
          }
        });
      }
    } else {
      this.$router.push('/user/penilaian')
    }

    // Check data profile jika belum diisi
    this.fetchPublisherProfile().then((res) => {
      if (!res.siup) {
        this.checkStatusProfile = true;
      } 
    });

  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit") next();
    else next("/user/home");
  },
};
</script>

<style></style>
